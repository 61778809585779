import React from 'react'
import ReactPlayer from 'react-player'
import tourVideo from '../../../assets/videos/tour.mp4';
import tourThumbnail from '../../../assets/images/tour-thumbnail.png'

function Tour() {
    return (
        <div className='pb-16'>
            <p className='text-3xl font-bold mt-12 mb-4'>Take a tour of our store</p>
            <div className='flex items-center justify-center'>
                <ReactPlayer
                    url={tourVideo}
                    width="500px"
                    height="325px"
                    muted={true}
                    light={tourThumbnail}
                    playing={true}
                    controls={true}
                />
            </div>
        </div>
    )
}

export default Tour