import React from "react";
import Banner from "../../components/Banner/Banner";
import BannerBottom from "../../components/Banner/BannerBottom";
import NewArrivals from "../../components/home/NewArrivals/NewArrivals";
import JKLakshmiImg from '../../assets/images/kts/jk_lakshmi_cement.jpg';
import AmbujaImg from '../../assets/images/kts/ambuja_cement.jpg';
import SupershaktiImg from '../../assets/images/kts/supershakti.png';
import TatatisconImg from '../../assets/images/kts/tatatiscon.jpg';
import SandImg from '../../assets/images/kts/sand2.jpg';
import storeBricks from '../../assets/images/kts/storeBricks.jpg';
import sampleCement from '../../assets/images/kts/gallery/12.jpg';
import gittiImg from '../../assets/images/kts/gallery/11.jpg';
import sandSampleOne from '../../assets/images/kts/gallery/17.jpg';
import Tour from "../../components/home/Tour/Tour";
const Home = () => {

  const catalogs = [
    {
      id: 'cement',
      title: 'Cement',
      products: [
        {
          _id: '1001',
          img: JKLakshmiImg,
          productName: 'JK Lakshmi Pro+'
        },
        {
          _id: '1002',
          img: AmbujaImg,
          productName: 'Ambuja'
        },
        {
          _id: '1003',
          img: sampleCement,
          productName: 'Sample'
        },
      ]
    },
    {
      id: 'bricks',
      title: 'Brick',
      products: [
        {
          _id: '2001',
          img: storeBricks,
          productName: 'Bricks'
        },
      ]
    },
    {
      id: 'stone-dust',
      title: 'Stone Dust',
      products: [
        {
          _id: '3001',
          img: SandImg,
          productName: 'Stone Dust'
        },
        {
          _id: '3002',
          img: sandSampleOne,
          productName: 'Stone Dust'
        },
      ]
    },
    {
      id: 'tmt-bars',
      title: 'TMT Bars',
      products: [
        {
          _id: '4001',
          img: SupershaktiImg,
          productName: 'Amba Sakti 550 SD'
        },
        {
          _id: '4002',
          img: TatatisconImg,
          productName: 'Tata Tiscon 550 SD'
        },
      ]
    },
    {
      id: 'stone-gritt',
      title: 'Stone Gritt',
      products: [
        {
          _id: '6001',
          img: gittiImg,
          productName: 'Stone Gritt'
        },
      ]
    },

  ]

  return (
    <div className="w-full mx-auto">
      <Banner />
      <BannerBottom />
      <div className="max-w-container mx-auto px-4">
        <Tour />
        {
          catalogs.map((catalog, index) => {
            return (
              <NewArrivals key={`${index}+${catalog.title}`} title={catalog.title} products={catalog.products} id={catalog.id} />
            );
          })
        }
      </div>
    </div>
  );
};

export default Home;
