import React from 'react'
import Image from '../../components/designLayouts/Image'


function ContactCard({ name, email, phone, profileImg }) {
    return (
        <div className="w-[350px] relative group">
            <div className="max-h-80 relative overflow-y-hidden ">
                <div>
                    <Image className="w-full h-full" imgSrc={profileImg} />
                </div>
            </div>
            <div className="py-6 flex flex-col gap-1 border-[1px] border-t-0 px-4">
                <div className="flex flex-col items-start justify-start font-titleFont">
                    <h2 className="text-lg text-primeColor font-bold">
                        {name}
                    </h2>
                    <p>Email: <a href={`mailto:${email}`}>{email}</a></p>
                    <p>Phone: <a href={`tel:${phone}`}>{phone}</a></p>
                </div>
            </div>
        </div>

    )
}

export default ContactCard