import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Slider from "react-slick";
import OwnerImg from '../../assets/images/kts/owner.jpg';
import { bannerImgOne, bannerImgTwo, bannerImgThree, bannerImgFour, bannerImgFive } from "../../assets/images";
import gittiImg from '../../assets/images/kts/gallery/11.jpg'
import Image from "../designLayouts/Image";

const CustomSlide = ({ Subtext, imgSrc, text, buttonLink, buttonText }) => {
  const location = useLocation();
  return (
    <div
      style={{
        position: "relative",
        backgroundColor: "#F5F5F3", // Gray background color
      }}
      className="flex justify-around items-center lg:flex-row md:flex-row sm:flex-col-reverse sm:pb-4"
    >
      <div
        style={{
          maxWidth: "450px", // Adjust the maxWidth as needed
        }}
        className="sm:ml-8"
      >
        <h1
          style={{
            marginBottom: "15px",
            color: "#000", // Black color
            fontWeight: "700",
          }}
          className="lg:text-[2.5rem] md:text-3xl sm:text-xl"
        >
          {text}
        </h1>
        <p
          style={{
            marginBottom: "25px",
            color: "#666", // Gray color
          }}
          className="lg:text-2xl md:text-xl sm:text-lg"
        >
          {Subtext}
        </p>

        <Link to={buttonLink} state={{ data: location.pathname.split("/")[1] }}>
          <button className="bg-primeColor text-white text-lg font-bodyFont w-[185px] h-[50px] hover:bg-black duration-300 font-bold">
            {buttonText}
          </button>
        </Link>
      </div>
      <div className="sm:pb-2">
        <Image imgSrc={imgSrc} className="lg:h-96 md:h-64" />
      </div>
    </div>
  )
};

const Banner = () => {
  const settings = {
    infinite: true,
    autoplay: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
    arrows: false,
  };

  const slides = [
    {
      imgSrc: OwnerImg,
      text: "Kanhya Timber Store",
      Subtext:
        "Welcome to our timber shop, where we've been proudly serving our community since 1971. Let us be your partner in turning your dream into reality.",
      buttonLink: "/contact",
      buttonText: "Contact Us",
    },
    {
      imgSrc: bannerImgOne,
      text: "Our Vision",
      Subtext:
        "We are committed to providing the highest quality materials for your construction projects. Transforming plots into homes with quality materials.",
      buttonLink: "/contact",
      buttonText: "Contact Us",
    },
    {
      imgSrc: bannerImgTwo,
      text: "Cement",
      Subtext:
        "Our cement stands as a testament to our commitment to quality. Trust in our cement to lay the foundation for your project's success.",
      buttonLink: "#cement",
      buttonText: "Cements",
    },
    {
      imgSrc: bannerImgThree,
      text: "Brick",
      Subtext:
        "Our bricks are the cornerstone of reliability and resilience. They form the solid backbone of any construction project.",
      buttonLink: "#bricks",
      buttonText: "Bricks",
    },
    {
      imgSrc: bannerImgFour,
      text: "Stone Dust",
      Subtext:
        "Our stone dust is sourced with care, ensuring purity and consistency. From foundation to finishing touches, rely on our stone dust for stability and quality.",
      buttonLink: "#stone-dust",
      buttonText: "Stone Dust",
    },
    {
      imgSrc: bannerImgFive,
      text: "TMT Bar",
      Subtext:
        "Our TMT bars are engineered for strength and durability, providing the structural integrity your project demands. Trust in our bars to reinforce your construction with confidence",
      buttonLink: "#tmt-bars",
      buttonText: "TMT Bars",
    },
    {
      imgSrc: gittiImg,
      text: "Stone Gritt",
      Subtext: "From pathways to landscapes, our stone gritt adds durability and beauty to every project. Choose quality, choose lasting elegance for your outdoor spaces",
      buttonLink: "#stone-gritt",
      buttonText: "stone gritt",
    },

    // Add more slides as needed
  ];

  return (
    <div className="w-full bg-white">
      <Slider {...settings} >
        {slides.map((slide, index) => (
          <CustomSlide key={index} {...slide} />
        ))}
      </Slider>
    </div>
  );
};

export default Banner;
