import React from "react";
import ContactCard from "./ContactCard";
import LilaImg from '../../assets/images/kts/owner.jpg';
import AshokImg from '../../assets/images/kts/test_resized.jpg';

const Contact = () => {

  const contactPersons = [
    {
      name: 'Mr. Lila Krishan Kakkar',
      email: 'ashok@kanhyatimber.com',
      phone: '+91 9654400500',
      profileImg: LilaImg,
    },
    {
      name: 'Mr. Ashok Kakkar',
      email: 'ashok@kanhyatimber.com',
      phone: '+91 9711424242',
      profileImg: AshokImg,
    }
  ];

  const address1 = 'A 50'
  const address2 = 'Mandawali Main Road'
  const address3 = 'Near Ram Ram Mandir'
  const address4 = 'Near Badi Masjid'
  const address5 = 'Delhi - 110092'

  const address = { address1, address2, address3, address4, address5 };

  return (
    <div className="max-w-container px-4 flex lg:flex-row lg:justify-around lg:items-start mb-8 md:flex-col sm:flex-col gap-4">
      <div className="flex flex-col justify-start items-center gap-2">
        <h1 className="text-4xl text-center mt-4 sm:text-2xl">Contact Us</h1>
        <p className="text-center text-xl mb-3 sm:text-base">If you have any questions or concerns, please feel free to contact us.</p>
        <div className="flex lg:flex-row md:flex-row sm:flex-col lg:justify-start lg:items-center md:justify-start md:items-center sm:justify-start sm:items-start gap-3">
          {contactPersons.map((contactPerson) => {
            return <ContactCard {...contactPerson} key={contactPerson.phone} />
          })}
        </div>
      </div>
      <div className="flex flex-col lg:justify-center lg:items-start gap-2 md:items-center sm:items-center">
        <h1 className="text-4xl text-center mt-4 mb-12">Our Location</h1>
        <div>
          <iframe src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3502.1103819843156!2d77.29507517550061!3d28.626453675668035!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMjjCsDM3JzM1LjIiTiA3N8KwMTcnNTEuNSJF!5e0!3m2!1sen!2sin!4v1715503441900!5m2!1sen!2sin" width={400} height={300} style={{ border: "0" }} allowFullScreen={true} loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
          <div className="flex flex-col mb-3 items-start mt-3">
            <p className="text-center text-lg leading-tight">{address.address2}</p>
            <p className="text-center text-lg leading-tight">{address.address1}</p>
            <p className="text-center text-lg leading-tight">{address.address3}</p>
            <p className="text-center text-lg leading-tight">{address.address4}</p>
            <p className="text-center text-lg leading-tight">{address.address5}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
