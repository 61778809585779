import React from "react";
import Slider from "react-slick";
import Product from "../../components/home/Products/Product";
import imgOne from '../../assets/images/kts/gallery/1.jpg';
import imgTwo from '../../assets/images/kts/gallery/2.jpg';
import imgThree from '../../assets/images/kts/gallery/3.jpg';
import imgFour from '../../assets/images/kts/gallery/4.jpg';
import imgFive from '../../assets/images/kts/gallery/5.jpg';
import imgSix from '../../assets/images/kts/gallery/6.jpg';
import imgSeven from '../../assets/images/kts/gallery/7.jpg';
import imgEight from '../../assets/images/kts/gallery/8.jpg';
import imgNine from '../../assets/images/kts/gallery/9.jpg';
import imgTen from '../../assets/images/kts/gallery/10.jpg';
import imgEleven from '../../assets/images/kts/gallery/11.jpg';
import imgTwelve from '../../assets/images/kts/gallery/12.jpg';
import imgThirteen from '../../assets/images/kts/gallery/13.jpg';
import imgFourteen from '../../assets/images/kts/gallery/14.jpg';
import imgFifteen from '../../assets/images/kts/gallery/15.jpg';
import imgSixteen from '../../assets/images/kts/gallery/16.jpg';
import SampleNextArrow from "../../components/home/NewArrivals/SampleNextArrow";
import SamplePrevArrow from "../../components/home/NewArrivals/SamplePrevArrow";

const Journal = () => {
  const settings = {
    infinite: true,
    speed: 500,
    rows: 1,
    slidesPerRow: 4,
    centerMode: true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1025,
        settings: {
          infinite: true,
          rows: 1,
          slidesPerRow: 3,
        },
      },
      {
        breakpoint: 769,
        settings: {
          infinite: true,
          rows: 1,
          slidesPerRow: 2,
        },
      },
      {
        breakpoint: 575,
        settings: {
          infinite: true,
          rows: 1,
          slidesPerRow: 1,
        },
      },
    ],
  };

  const images = [
    {
      _id: '5001',
      img: imgOne,
    },
    {
      _id: '5002',
      img: imgTwo,
    },
    {
      _id: '5003',
      img: imgThree,
    },
    {
      _id: '5004',
      img: imgFour,
    },
    {
      _id: '5005',
      img: imgFive,
    },
    {
      _id: '5006',
      img: imgSix,
    },
    {
      _id: '5007',
      img: imgSeven,
    },
    {
      _id: '5008',
      img: imgEight,
    },
    {
      _id: '5009',
      img: imgNine,
    },
    {
      _id: '5010',
      img: imgTen,
    },
    {
      _id: '5011',
      img: imgEleven,
    },
    {
      _id: '5012',
      img: imgTwelve,
    },
    {
      _id: '5013',
      img: imgThirteen,
    },
    {
      _id: '5014',
      img: imgFourteen,
    },
    {
      _id: '5015',
      img: imgFifteen,
    },
    {
      _id: '5016',
      img: imgSixteen,
    },
  ]
  return (
    <div className="w-full pb-16">
      <Slider {...settings}>
        {
          images.map((image) => {
            return (
              <div className="px-2" key={image._id}>
                <Product
                  img={image.img}
                />
              </div>
            );
          })
        }
      </Slider>
    </div>
  );
};

export default Journal;
