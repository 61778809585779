import React from "react";
import Slider from "react-slick";
import Heading from "../Products/Heading";
import Product from "../Products/Product";
import {
  newArrOne,
  newArrTwo,
  newArrThree,
  newArrFour,
} from "../../../assets/images/index";
import SampleNextArrow from "./SampleNextArrow";
import SamplePrevArrow from "./SamplePrevArrow";

const NewArrivals = (props) => {
  const settings = {
    infinite: true,
    speed: 500,
    // slidesToShow: 1,
    // slidesToScroll: 1,
    rows: 1,
    slidesPerRow: 4,
    centerMode: true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1025,
        settings: {
          infinite: true,
          rows: 1,
          slidesPerRow: 3,
        },
      },
      {
        breakpoint: 769,
        settings: {
          infinite: true,
          rows: 1,
          slidesPerRow: 2,
        },
      },
      {
        breakpoint: 575,
        settings: {
          infinite: true,
          rows: 1,
          slidesPerRow: 1,
        },
      },
    ],
  };
  return (
    <div className="w-full pb-16" id={props.id}>
      <Heading heading={props.title} />
      <Slider {...settings} adaptiveHeight={true}>
        {
          props.products.map((product) => {
            return (
              <div className="px-2" key={product._id}>
                <Product
                  _id={product._id}
                  img={product.img}
                  productName={product.productName}
                />
              </div>
            );
          })
        }
      </Slider>
    </div>
  );
};

export default NewArrivals;
